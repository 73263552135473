import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

//import images
import services from '../../assets/images/construction/serveices.png';

export default class ProjectPlanning extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="features-absolute rounded bg-white shadow">
                  <Row>
                    <Col lg={8} md={12} className="my-4">
                      <Row>
                        <Col md={6} xs={12}>
                          <div className="d-flex features feature-primary p-4">
                            <div className="icon text-center rounded-circle text-primary me-3 mt-2">
                              <i className="uil uil-swatchbook
 align-middle h4 mb-0"></i>
                            </div>
                            <div className="flex-1">
                              <h4 className="title">Project Consultancy</h4>
                              <p className="text-muted para mb-0">
                              Expertise in Process Optimization and knowledge of the mining sector forms the foundation of our consultancy service.
                              </p>
                            </div>
                          </div>
                        </Col>

                        <Col md={6} xs={12}>
                          <div className="d-flex features feature-primary p-4">
                            <div className="icon text-center rounded-circle text-primary me-3 mt-2">
                              <i className="mdi mdi-code-tags
 align-middle h4 mb-0"></i>
                            </div>
                            <div className="flex-1">
                              <h4 className="title">Custom Software</h4>
                              <p className="text-muted para mb-0">
                              A solution tailored to your needs. Unlike off-the-shelf software solutions, our custom software is crafted to fit your operations. 
                              </p>
                            </div>
                          </div>
                        </Col>

                        <Col md={6} xs={12}>
                          <div className="d-flex features feature-primary p-4">
                            <div className="icon text-center rounded-circle text-primary me-3 mt-2">
                              <i className="mdi mdi-repeat
 align-middle h4 mb-0"></i>
                            </div>
                            <div className="flex-1">
                              <h4 className="title">Iterative Development</h4>
                              <p className="text-muted para mb-0">
                              After each development phase, we gather feedback to align with your goals. This iterative approach ensures the final product is functional, refined, and benefits from consistent insights and testing.
                              </p>
                            </div>
                          </div>
                        </Col>

                        <Col md={6} xs={12}>
                          <div className="d-flex features feature-primary p-4">
                            <div className="icon text-center rounded-circle text-primary me-3 mt-2">
                              <i className="uil uil-cog align-middle h4 mb-0"></i>
                            </div>
                            <div className="flex-1">
                              <h4 className="title">Reliable Maintenance</h4>
                              <p className="text-muted para mb-0">
                              Our relationship doesn’t end after deployment. We believe in forging lasting partnerships. Thus, we offer ongoing support ensuring that the software continues to serve your evolving needs.
                              </p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>

                    <Col lg={4} className="d-none d-lg-block position-relative">
                      <img
                        src={services}
                        className="img-fluid mx-auto d-block construction-img"
                        alt=""
                      />
                    </Col>
                  </Row>
                </div>
              </Col>              
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
