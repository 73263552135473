import React, { Component } from 'react';
import { Container, Row, Col, CardBody, Card } from 'reactstrap';
import { Link } from "react-router-dom";

import FeatherIcon from 'feather-icons-react';
import * as Unicons from '@iconscout/react-unicons';

//Import Images
import social3 from "../../assets/images/social/social.svg";

class Features extends Component {
    render() {
        return (
            <React.Fragment>
                <Container className="mt-60 mt-30">
                    <Row className="justify-content-center">
                            <Col xs={12}>
                                <div className="section-title text-center mb-4 pb-2">
                                    <h4 className="title mb-4">Software that does the  <span className="text-primary">right thing</span> for you</h4>
                                    <p className="text-muted para-desc mx-auto mb-0">Start working with <span className="text-primary fw-bold">Albatros SA</span>. We know the mining sector inside and out, and we combine that with our tech skills. This knowledge lets us build software that's tailored for mining needs. While others might make general-purpose software, ours is custom-made for the unique challenges of mining.</p>
                                </div>
                            </Col>
                        </Row>
                </Container>
                <Container className="mt-60 mt-60">
                    <Row className="align-items-center">

                        <Col lg={6} xs={12} className="col--6">
                            <Row className="me-lg-4" id="counter">
                                <Col md={6} xs={12}>
                                    <Row>
                                        <Col xs={12} className="mt-4 mt-lg-0 pt-2 pt-lg-0">
                                            <Card className="features feature-primary explore-feature border-0 rounded text-center shadow">
                                                <CardBody>
                                                    <div className="icons rounded-circle shadow-lg d-inline-block">
                                                        <i><FeatherIcon icon="pen-tool" className="fea" /></i>
                                                    </div>
                                                    <h5 className="mt-3 title">Full Project Team</h5>
                                                    <p className="text-muted mb-0">Developers, Analysts, Project Managers (…) everyone needed to execute and oversee the full development process, leading to efficient project execution.</p>
                                                </CardBody>
                                            </Card>
                                        </Col>

                                        <Col xs={12} className="mt-4 pt-2">
                                            <Card className="features feature-primary explore-feature border-0 rounded text-center shadow">
                                                <CardBody>
                                                    <div className="icons rounded-circle shadow-lg d-inline-block">
                                                        <i><FeatherIcon icon="aperture" className="fea" /></i>
                                                    </div>
                                                    <h5 className="mt-3 title">Mining Industry Expertise</h5>
                                                    <p className="text-muted mb-0">A dedication to understanding the mining industry, including its challenges, regulations, and technology needs. Expertise in the use of technology to improve processes, safety, and efficiency.</p>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col md={6} xs={12}>
                                    <Row className="pt-lg-4 mt-lg-4">
                                        <Col xs={12} className="mt-4 pt-2">
                                            <Card className="features feature-primary explore-feature border-0 rounded text-center shadow">
                                                <CardBody>
                                                    <div className="icons rounded-circle shadow-lg d-inline-block">
                                                        <i><FeatherIcon icon="smartphone" className="fea" /></i>
                                                    </div>
                                                    <h5 className="mt-3 title">Innovation and R&D</h5>
                                                    <p className="text-muted mb-0">A commitment to continuous research and development to stay updating on emerging technologies and industry trends. A culture of innovation that fosters creativity and problem-solving.</p>
                                                </CardBody>
                                            </Card>
                                        </Col>

                                        <Col xs={12} className="mt-4 pt-2">
                                            <Card className="features feature-primary explore-feature border-0 rounded text-center shadow">
                                                <CardBody>
                                                    <div className="icons rounded-circle shadow-lg d-inline-block">
                                                        <i><FeatherIcon icon="message-circle" className="fea" /></i>
                                                    </div>
                                                    <h5 className="mt-3 title">Low-code Development</h5>
                                                    <p className="text-muted mb-0">Prioritizing low-code development platforms and tools that accelerate and strengthen software development.</p>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                        <Col lg={6} xs={12} className="col--6 mt-4 pt-2 mt-lg-0 pt-lg-0">
                            <div className="section-title ms-lg-4">
                                <p className="text-primary h2 mb-3"><i className="uil uil-apps"></i></p>
                                <h4 className="title mb-3">Our Great Features</h4>
                                <p className="text-muted">Our team comprises experts from development to project management, ensuring efficient execution. We deeply understand the mining industry's challenges and employ technology to enhance processes and safety. We're always updated on the latest trends, foster a culture of innovation, and prioritize low-code platforms for faster, robust software solutions. This is our unique value proposition.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>

                
            </React.Fragment>
        );
    }
}

export default Features;