import React from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

const WorkProcess = () => {
    return (
      <React.Fragment>
        <Container className="mt-60 mt-100">
          <Row className="justify-content-center">
            <Col xs={12}>
              <div className="section-title text-center mb-2 mt-5 pb-2">
                <p className="text-muted para-desc mx-auto mt-4 mb-0">
                  Start working with{" "}
                  <span className="text-primary fw-bold">
                    Albatros SA
                  </span>{" "}
                  to create custom-software solutions. Our development consists of three major steps.
                </p>  
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={4} className="mt-4 pt-2">
              <Card className="features feature-primary feature-clean work-process bg-transparent process-arrow border-0 text-center">
                <div className="icons text-center mx-auto">
                  <i className="uil uil-presentation-edit d-block rounded h3 mb-0"></i>
                </div>

                <CardBody>
                  <h5 className="text-dark">Disovery and Design</h5>
                  <p className="text-muted mb-0">
                  The Discovery phase analyzes business processes and designs tailored software by interviewing stakeholders and documenting workflows. It sets initial software requirements. Post-discovery, we clearly define scope to align solution costs with initial estimates.
                  </p>
                </CardBody>
              </Card>
            </Col>

            <Col md={4} className="mt-md-5 pt-md-3 mt-4 pt-2">
              <Card className="features feature-primary feature-clean work-process bg-transparent process-arrow border-0 text-center">
                <div className="icons text-center mx-auto">
                  <i className="mdi mdi-open-in-app
 d-block rounded h3 mb-0"></i>
                </div>

                <CardBody>
                  <h5 className="text-dark">Implementation</h5>
                  <p className="text-muted mb-0">
                  The Implementation phase is designed to craft custom software solutions tailored precisely for each client. Using an iterative process, we enable clients to witness tangible value from the earliest stages. Development effort varies in execution time, based on the unique requirements of the solution.
                  </p>
                </CardBody>
              </Card>
            </Col>

            <Col md={4} className="mt-md-5 pt-md-5 mt-4 pt-2">
              <Card className="features feature-clean work-process bg-transparent d-none-arrow border-0 text-center">
                <div className="icons text-primary text-center mx-auto">
                  <i className="mdi mdi-tune-variant d-block rounded h3 mb-0"></i>
                </div>

                <CardBody>
                  <h5 className="text-dark">Long-Term Maintenance</h5>
                  <p className="text-muted mb-0">
                  The Long-Term Maintenance phase ensures cloud-based application maintenance, covering system upkeep, cloud fees, database backups, and upgrades. Post-release feature additions and issue resolutions are charged hourly based on specific scopes.
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );  
};
export default WorkProcess;
