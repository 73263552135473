import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

//import bg image
import bg_image from "../../assets/images/construction/bg.jpg";

export default class Section extends Component {
  render() {
    return (
      <React.Fragment>
        <section
          className="bg-half-260 d-table w-100"
          style={{ background: `url(${bg_image}) center center` }}
        >
          <div className="bg-overlay" style={{ opacity: "0.65" }}></div>
          <Container>
            <Row className="mt-5 mt-md-4">
              <Col xs={12}>
                <div className="title-heading">
                  <h4 className="display-6 mb-4 text-white title-dark">
                  Put your <span className="fw-bold">Digital Transformation</span> into Overdrive.
                  </h4>
                  <p className="para-desc text-white" style={{
                    backgroundColor: 'rgba(220, 211, 211, 0.1)',
                    padding: '15px',
                    backdropFilter: 'blur(2px)',
                    borderRadius: '13px'
                  }}>
                    Our goal is to be a trusted tech partner for Canadian mining operations. We focus on creating straightforward, user-friendly software solutions that directly address their specific needs, enhancing efficiency and success.
                  </p>
                  {/* <div className="mt-4 pt-2">
                    <Link to="#" className="btn btn-primary">
                      Get Started
                    </Link>
                  </div> */}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
