import React from 'react'
import { Redirect } from "react-router-dom";
import Albatros from '../pages/Albatros/index';

const routes = [
  {
    path: '/',
    exact: true,
    isWithoutLayout: false,
    isTopbarDark: false,
    component: Albatros,
  },

  // Catch all
  {
    path: '/*',
    component: () => <Redirect to="/" />,
  },
];

export default routes;
