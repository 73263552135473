import React, { useState } from "react";
import SectionTitle from "../../components/Shared/SectionTitle";
import { Container, Row, Col, Card, CardBody } from "reactstrap";

// import FeatherIcon
import FeatherIcon from "feather-icons-react";

// Modal Video
import ModalVideo from "react-modal-video";
import "../../../node_modules/react-modal-video/scss/modal-video.scss";

// import image
import ab01 from "../../assets/images/course/online/ab01.jpg";
import ab02 from "../../assets/images/course/online/ab02.jpg";
import ab03 from "../../assets/images/course/online/ab03.jpg";
import { Link } from "react-router-dom";



const WhatWeDo = () => {
  const [open, setOpen] = useState(false);

  const itSolutionWhatwedo = [
    {
      id: 1,
      icon: "mdi mdi-graphql",
      heading: "Software Operations Architecture",
      desc: "",
    },
    {
      id: 2,
      icon: "mdi mdi-target-variant",
      heading: "Custom Software Development",
      desc: "",
    },
    {
      id: 3,
      icon: "mdi mdi-git",
      heading: "Customization and Integration Services",
      desc: "",
    },
    {
      id: 4,
      icon: "uil uil-cog",
      heading: "Ongoing Software Maintenance",
      desc: "",
    },
  ];

  return (
    <React.Fragment>
      <section className="section">
        <Container>
          <Row className="justify-content-center">
            <div className="col-12 text-center">
              {/* render Section title */}
              <SectionTitle
                title="How can we support your operation?"
                desc="We strive to be a reliable and strategic technology partner that contributes to the success and efficiency 
                of Canadian mining operations through innovative, user-centric, and tailor-made software solutions."
              />
            </div>
          </Row>
          <Row>
            {itSolutionWhatwedo.map((items, key) => (
              <Col lg={3} md={4} className="mt-4 pt-2" key={key}>
                <div className="card features feature-primary feature-full-bg rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
                  <span className="h1 icon-color">
                    <i className={items.icon}></i>
                  </span>
                  <div className="card-body p-0 content">
                    <h5>{items.heading}</h5>
                    <p className="para text-muted mb-0">{items.desc}</p>
                  </div>
                  <span className="big-icon text-center">
                    <i className={items.icon}></i>
                  </span>
                </div>
              </Col>
            ))}
            
            
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};
export default WhatWeDo;
